/********** Add Your Global CSS Here **********/
body {
  font-size: 16px !important;
}

h1,
h2,
h3,
h4,
h5,
h6,
a,
p,
li,
input,
textarea,
span,
div,
html,
body,
html a {
  margin-bottom: 0em;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  /*text-shadow: 1px 1px 1px rgba(0, 0, 0, 0.004);*/
}

hr {
  border-style: solid;
  border-color: #eaeaea;
}

.scrollbar-track-y,
.scrollbar-thumb-y {
  width: 5px !important;
}

.scrollbar-track-x,
.scrollbar-thumb-x {
  height: 5px !important;
}

.scrollbar-thumb {
  border-radius: 0 !important;
}

.ant-popover-placement-bottom > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-bottomLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-bottomRight
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-top > .ant-popover-content > .ant-popover-arrow:after,
.ant-popover-placement-topLeft
  > .ant-popover-content
  > .ant-popover-arrow:after,
.ant-popover-placement-topRight
  > .ant-popover-content
  > .ant-popover-arrow:after {
  left: 0;
  margin-left: -4px;
}

.ant-btn.ant-btn.disabled,
.ant-btn.ant-btn[disabled],
.ant-btn.ant-btn.disabled:hover,
.ant-btn.ant-btn[disabled]:hover,
.ant-btn.ant-btn.disabled:focus,
.ant-btn.ant-btn[disabled]:focus,
.ant-btn.ant-btn.disabled:active,
.ant-btn.ant-btn[disabled]:active,
.ant-btn.ant-btn.disabled.active,
.ant-btn.ant-btn[disabled].active {
  cursor: default !important;
}

.ant-pagination-disabled, 
.ant-pagination-disabled:hover, 
.ant-pagination-disabled:focus,
.ant-pagination-disabled a, 
.ant-pagination-disabled:hover a, 
.ant-pagination-disabled:focus a, 
.ant-pagination-disabled .ant-pagination-item-link, 
.ant-pagination-disabled:hover .ant-pagination-item-link, 
.ant-pagination-disabled:focus .ant-pagination-item-link {
  cursor: default !important;
  pointer-events: none;
}

.ant-tabs {
  font-size: 1em;
}

.ant-tabs-nav .ant-tabs-tab-disabled, .ant-tabs-nav .ant-tabs-tab-disabled:hover, 
.ant-tabs-tab-btn-disabled, .ant-radio-button.ant-radio-button-disabled, .ant-radio-button-wrapper.ant-radio-button-wrapper-disabled {
  cursor: default !important;
}
/********** Add Your Global RTL CSS Here **********/
/* Popover */
html[dir='rtl'] .ant-popover {
  text-align: right;
}

/* Ecommerce Card */
html[dir='rtl'] .isoCardInfoForm .ant-input {
  text-align: right;
}

/* Modal */
html[dir='rtl'] .has-success.has-feedback:after,
html[dir='rtl'] .has-warning.has-feedback:after,
html[dir='rtl'] .has-error.has-feedback:after,
html[dir='rtl'] .is-validating.has-feedback:after {
  left: 0;
  right: auto;
}

html[dir='rtl'] .ant-modal-close {
  right: inherit;
  left: 0;
}

html[dir='rtl'] .ant-modal-footer {
  text-align: left;
}

html[dir='rtl'] .ant-modal-footer button + button {
  margin-left: 0;
  margin-right: 8px;
}

html[dir='rtl'] .ant-confirm-body .ant-confirm-content {
  margin-right: 42px;
}

html[dir='rtl'] .ant-btn > .anticon + span,
html[dir='rtl'] .ant-btn > span + .anticon {
  margin-right: 0.5em;
}

html[dir='rtl'] .ant-btn-loading span {
  margin-left: 0;
  margin-right: 0.5em;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline) {
  padding-left: 25px;
  padding-right: 29px;
}

html[dir='rtl']
  .ant-btn.ant-btn-loading:not(.ant-btn-circle):not(.ant-btn-circle-outline)
  .anticon {
  margin-right: -14px;
  margin-left: 0;
}

/* Confirm */
html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-body > .anticon {
  margin-left: 16px;
  margin-right: 0;
  float: right;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns {
  float: left;
}

html[dir='rtl'] .ant-modal.ant-confirm .ant-confirm-btns button + button {
  margin-right: 10px;
  margin-left: 0;
}

/* Message */
html[dir='rtl'] .ant-message .anticon {
  margin-left: 8px;
  margin-right: 0;
}

/* Pop Confirm */
html[dir='rtl'] .ant-popover-message-title {
  padding-right: 20px;
  padding-left: 0;
}

html[dir='rtl'] .ant-popover-buttons {
  text-align: left;
}

/* Notification */
html[dir='rtl']
  .ant-notification-notice-closable
  .ant-notification-notice-message {
  padding-left: 24px;
  padding-right: 0;
}

html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-message,
html[dir='rtl']
  .ant-notification-notice-with-icon
  .ant-notification-notice-description {
  margin-right: 48px;
}

html[dir='rtl'] .ant-notification-notice-close {
  right: auto;
  left: 16px;
}

html[dir='rtl'] .ant-notification-notice-with-icon {
  left: 0;
}

/* Dropzone */
html[dir='rtl'] .dz-hidden-input {
  display: none;
}

.ant-select-dropdown-menu-item-disabled {
  cursor: default !important;
}

.ant-back-top {
  right: 40px !important;
}

.fa-ease-in-out-spin{
  -webkit-animation: fa-spin 2s infinite ease-in-out;
  animation: fa-spin 2s infinite ease-in-out;
}

@media print {
  @page {
    margin: .5in;
  }

  .ant-layout-footer,
  .ant-layout {
    background-color: transparent !important;
  }

  body {
    font-size: 8pt !important;
  }

  .ant-layout {
    height: auto !important;
  }

  .isomorphicSidebar {
    flex: 0 0 0;
    max-width: 0;
    min-width: 0;
    width: 0;
    display: none !important;
  }

  .isomorphicTopbar,
  .ant-back-top {
    display: none !important;
  }

  .isoContentMainLayout {
    position: relative !important;
    width: auto !important;
    height: auto !important;
    overflow: visible !important;
    transform: scale(1);
    transform-origin: top left;
  }

  .fefDashboard {
    padding: 0;
  }
}
